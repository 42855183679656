<template>
  <section class="task-filter">
    <section>
      <header><h5>Search</h5></header>
      <form class="search-form" @submit.stop.prevent="search">
        <div class="form-row">
          <hub-text-field v-model="qs" autocomplete="off" :rows="3" />
        </div>
        <div class="form-row submit">
          <hub-button type="submit" color="primary" variant="contained"><hub-icon name="magnify" size="sm"></hub-icon></hub-button>
        </div>
      </form>
    </section>
    <hr />
    <section>
      <header><h5>Filter</h5></header>
      <form class="filter-form" @submit.stop.prevent="search">
        <div class="form-row">
          <hub-multiselect
            :value="dueAt"
            label="Due at"
            placeholder=""
            :options="options.dueAt"
            :searchable="false"
            :can-clear="false"
            :can-deselect="false"
            @change="props => applyMultiselectFiltering('dueAt', props)"
          />
        </div>

        <div class="form-row">
          <div v-if="isGetStatusCollectionPending" style="place-self: center">
            <hub-icon name="loading" spin size="lg"></hub-icon>
          </div>
          <hub-checklist
            v-else
            :value="status"
            label="Status"
            :test-id="'filter-statuses'"
            :options="statuses"
            @update="props => applyChecklistFiltering('status', props)"
          />
        </div>
        <div class="form-row assignees-row">
          <div class="assignees">
            <hub-assignees
              :value="assignees"
              label="Assigned to"
              :test-id="'task-assigned-to'"
              placeholder=""
              @change="props => applyTagsFiltering('assignees', props)"
            />
          </div>
          <div class="conjugation">
            <hub-toggle
              :options="['or', 'and']"
              :initial-selected="assigneesConjugation"
              :capitalize="true"
              @changed="option => applyMultiselectFiltering('assigneesConjugation', option)"
            />
          </div>
        </div>
        <div class="form-row">
          <hub-assignees
            :value="projectAssignees"
            label="Responsible Attorney"
            :test-id="'task-assignees-resp-att'"
            placeholder=""
            @change="props => applyTagsFiltering('projectAssignees', props)"
          />
        </div>
        <div class="form-row">
          <hub-worklfows
            :value="workflows"
            label="Workflows"
            :test-id="'task-workflows'"
            placeholder=""
            @change="props => applyWorkflowFiltering(props)"
          />
        </div>
      </form>
    </section>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Checklist from '@/components/common/Checklist';
import Assignees from '@/components/Assignees';
import Wokrflows from './WorkflowFilter.vue';
import Toggle from '@/components/common/MultipleToggleButton';

import Multiselect from '@/components/common/Multiselect';
import dueAt from '@/components/search/dueAt';

export default {
  components: {
    'hub-text-field': TextField,
    'hub-button': Button,
    'hub-multiselect': Multiselect,
    'hub-checklist': Checklist,
    'hub-assignees': Assignees,
    'hub-icon': Icon,
    'hub-worklfows': Wokrflows,
    'hub-toggle': Toggle
  },
  props: {
    filter: {
      required: false,
      default: () => null,
      type: Object
    }
  },
  emits: ['changed'],
  data() {
    const options = {
      dueAt: [
        {
          label: 'Show all',
          value: ''
        },
        ...dueAt().map(s => ({
          label: s.name[0].toUpperCase() + s.name.slice(1),
          value: s.name
        }))
      ]
    };
    return {
      qs: '',
      dueAt: '',
      status: [],
      assignees: [],
      projectAssignees: [],
      workflows: [],
      assigneesConjugation: 'or',

      options
    };
  },
  computed: {
    ...mapState({
      statuses: s => s.tasks.statusCollection,
      isGetStatusCollectionPending: s => s.tasks.isGetStatusCollectionPending
    }),
    ...mapGetters({
      filters: 'filters/all'
    })
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      if (!this.filter) {
        return;
      }

      this.qs = this.filter.qs;
      this.dueAt = (this.options.dueAt.find(item => item.value === this.filter.dueAt) || this.options.dueAt[0]).value;
      this.status = this.filter.status;
      this.assignees = this.filter.assignees?.filter(a => a) || [];
      this.projectAssignees = this.filter.projectAssignees?.filter(a => a) || [];
      this.assigneesConjugation = this.filter.assigneesConjugation || 'or';

      this.workflows = this.filter.workflows || [];
    },
    search(props) {
      this.filterChanged();
      this.$trackEvent(`Task list searched`);
    },
    applyMultiselectFiltering(fieldName, props) {
      this[fieldName] = props;
      this.filterChanged();
      this.$trackEvent(`Task list filtered by '${fieldName}'`);
    },
    applyTagsFiltering(fieldName, value) {
      this[fieldName] = value?.length ? value : [];

      this.filterChanged();
      this.$trackEvent(`Task list filtered by '${fieldName}'`);
    },

    applyChecklistFiltering(fieldName, props) {
      this[fieldName] = props?.length ? props : '';

      this.filterChanged();

      this.$trackEvent(`Task list filtered by '${fieldName}'`);
    },
    applyWorkflowFiltering(props) {
      this.workflows = props?.length ? props : [];

      this.filterChanged();

      this.$trackEvent(`Task list filtered by '${JSON.stringify(props)}'`);
    },

    filterChanged() {
      const { dueAt, status, assignees, projectAssignees, qs, workflows, assigneesConjugation } = this;
      const existing = { dueAt, status, assignees, projectAssignees, qs, workflows, assigneesConjugation };
      this.$emit('changed', existing);
    }
  }
};
</script>

<style lang="scss" scoped>
.assignees-row {
  display: grid;

  grid-template-columns: 1fr max-content;
  .assignees {
    align-self: flex-start;
    margin-right: 5px;
  }

  .conjugation {
    min-width: 50px;
    margin-top: 20px;
    align-self: center;
  }
}
.task-filter {
  padding: 0.5rem;
  height: 100%;
  position: relative;
  overflow-y: scroll;

  .search-form,
  .filter-form {
    display: grid;
    grid-gap: 0.5rem;

    background: var(--theme-surface);

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }
    &.dirty {
      .error {
        display: block;
      }
    }
  }

  .search-form {
    grid-template-columns: minmax(0, 1fr) max-content;
    align-items: center;

    .submit {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
  .filter-form {
    grid-template-columns: minmax(0, 1fr);
  }

  h5 {
    margin-bottom: 0.75rem;
  }
}
</style>

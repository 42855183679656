<template>
  <ul>
    <li v-for="option of options" :key="option" :class="{ selected: option === selected }" @click="onSelected(option)">
      {{ capitalize ? option.toUpperCase() : option }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    initialSelected: {
      type: String,
      default: ''
    },
    capitalize: {
      type: Boolean,
      default: false
    }
  },
  emits: ['changed'],
  data() {
    return {
      selected: this.initialSelected
    };
  },
  methods: {
    onSelected(option) {
      if (this.selected === option) {
        return;
      }
      this.selected = option;
      this.$emit('changed', option);
    }
  }
};
</script>

<style scoped lang="scss">
ul {
  display: table;
  list-style-type: none;
  overflow: hidden;
  background: transparent;
}

li:first-child {
  box-shadow: 0;
  border-top-left-radius: 3px 3px;
  border-bottom-left-radius: 3px 3px;
}

li:last-child {
  box-shadow: 0;
  border-top-right-radius: 3px 3px;
  border-bottom-right-radius: 3px 3px;
}

li {
  float: left;
  vertical-align: middle;
  box-shadow: 0px 2px 0px 2px var(--theme-surface);
  cursor: pointer;
  padding: 0.3rem;
  background: var(--theme-background);
  min-width: 45px;
  text-align: center;
  user-select: none;
  &:hover {
    background: var(--theme-secondary);
    color: var(--theme-on-secondary);
  }
}
.selected {
  background: var(--theme-primary);
  color: var(--theme-on-primary);
  cursor: auto;
  &:hover {
    background: var(--theme-primary);
    color: var(--theme-on-primary);
  }
}
</style>

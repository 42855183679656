<template>
  <section class="tasks">
    <div class="pane-wrapper">
      <hub-task-filter v-if="!isGetStatusCollectionPending" @changed="searchTasks" />
    </div>
    <div v-if="isGetCollectionRequestPending || !isReady" style="place-self: center">
      <hub-icon name="loading" spin size="lg"></hub-icon>
    </div>
    <section v-else class="list-wrapper">
      <div class="header-wrapper">
        <div />
        <div>
          <hub-invention-lookup-button @select="showAddTaskPane">+ Add Task</hub-invention-lookup-button>
          <div v-if="total"><label>Total:</label>&nbsp; {{ total > 9999 ? '> 10k' : total.toString() }}</div>
        </div>
      </div>
      <hub-task-list :collection="collection" :total="total" :size="size" :skip="skip" @open="open" @navigate="navigate" />
    </section>

    <div>
      <hub-pane v-if="!!activePane" :name="activePane.name" :title="activePane.title" :data="activePane.data" class="pane-wrapper" @close="close" />
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Icon from './../common/Icon';
import Pane from './../inventions/Pane';
import List from './ListWithPaging';

import Filter from './../boards/filters/Filter';

import InventionLookupButton from './InventionLookupButton';

import dueAt from './dueAt';

export default {
  components: {
    'hub-pane': Pane,
    'hub-icon': Icon,
    'hub-task-filter': Filter,
    'hub-task-list': List,
    'hub-invention-lookup-button': InventionLookupButton
  },
  data() {
    return {
      isReady: true,
      activePane: null,
      filter: null
    };
  },

  computed: {
    ...mapState({
      email: s => s.identity.email,
      collection: s => s.filters.tasks.collection,
      total: s => s.filters.tasks.total,
      skip: s => s.filters.tasks.skip,
      size: s => s.filters.tasks.size,
      isGetCollectionRequestPending: s => s.filters.tasks.isGetCollectionRequestPending,
      statuses: s => s.tasks.statusCollection
    }),
    ...mapGetters({
      filters: 'filters/all'
    }),
    isDirty() {
      return Object.keys(this.$route.query).filter(key => !['fid', 'size', 'skip', 'sort'].includes(key)).length > 0;
    }
  },
  watch: {
    '$route.params.id': {
      handler() {
        if (this.$route.params?.id) {
          const task = this.collection.find(t => t.id === this.$route.params?.id);
          this.activePane = {
            name: 'tasks/EditTask',
            title: 'Task',
            data: task || { id: this.$route.params?.id }
          };
        } else {
          this.activePane = null;
        }
      },
      immediate: true
    },
    '$route.query': {
      handler(a, b) {
        if (JSON.stringify(a) === JSON.stringify(b)) {
          return;
        }
        this.searchTasks(this.filter);
      },

      deep: true
    }
  },
  async created() {
    this.$trackEvent(`Task search opened`);

    this.isReady = false;
    await this.$store.dispatch('tasks/getStatusCollection', {
      assignees: [],
      size: 0
    });

    await this.searchTasks(null);

    this.isReady = true;
  },
  methods: {
    async nextPage() {
      const params = new URLSearchParams(document.location.search);
      await this.searchTasks(this.filter, params.get('size') ? +params.get('size') : 50, params.get('skip') ? +params.get('skip') : 0);
    },
    async searchTasks(filter, size = 50, skip = 0) {
      this.filter = filter;
      const { from, to } = dueAt().find(item => item.name === this.filter?.dueAt) || {};

      await this.$store.dispatch('filters/preview', {
        qs: this.filter?.qs,
        status: this.filter?.status,
        assignees: this.filter?.assignees,
        assigneesConjugation: this.filter?.assigneesConjugation || 'or',
        projectAssignees: this.filter?.projectAssignees,
        dueAt: from && to ? ['from:' + from.toISOString(), 'to:' + to.toISOString()] : from === null && to === null ? ['null'] : undefined,
        workflows: this.filter?.workflows,
        size,
        skip
      });
    },
    open(item) {
      this.$router
        .push({
          path: `/tasks/${item.id}`,
          query: {
            ...this.$route.query
          }
        })
        .catch(e => {});
      this.$trackEvent(`Edit Task pane opened (tasks)`);
    },
    close(cc) {
      if (!!cc) {
        this.searchTasks(this.filter);

        this.$store.dispatch('tasks/getStatusCollection', {
          assignees: [],
          size: 0
        });

        if (cc.status === 'created') {
          this.activePane = null;
        }
      } else {
        this.$router
          .push({
            path: `/tasks`,
            query: {
              ...this.$route.query
            }
          })
          .catch(e => {});
        this.activePane = null;
      }
    },

    showAddTaskPane(invention) {
      this.$trackEvent(`Add Task pane opened (tasks)`);
      this.activePane = {
        name: 'tasks/CreateTask',
        title: 'Create Task',
        data: { inventionId: invention.id, task: { createNew: true, workflow: {} } }
      };
    },
    async navigate(obj) {
      this.$trackEvent(`Task list pagination applied`);
      this.$router
        .push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            ...obj
          }
        })
        .catch(e => {});
    }
  }
};
</script>

<style lang="scss" scoped>
.tasks {
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 0 0.5rem;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(400px, 2fr) minmax(0, 4fr) minmax(0, 3fr);

  .list-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    grid-gap: 0 0.5rem;
    grid-template-rows: minmax(0, max-content) minmax(0, max-content) minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.75rem;
      margin: 0;
      padding: 0.5rem 0;
      font-weight: 400;
      margin-right: 6px;

      line-height: 1;
      label {
        font-weight: 500;
      }

      > * {
        height: 100%;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 2rem;
        }
      }

      .filter {
        position: relative;

        i {
          color: var(--theme-on-surface-accent);
        }

        h2 {
          // cursor: pointer;
          background-color: transparent;
          margin-right: 0.5rem;

          small {
            cursor: default;
            color: var(--theme-error);
          }
        }
      }
    }
  }

  .pane-wrapper {
    background-color: var(--theme-surface);
  }
}
</style>

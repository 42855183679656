<template>
  <section class="search">
    <div>
      <hub-button variant="icon" class="close-button" @click="$emit('close')"><hub-icon name="close"></hub-icon></hub-button>
    </div>

    <hub-text-field v-model="searchText" :test-id="'search-invention'" @enter="onEnter"></hub-text-field>

    <ul v-if="isRequestFailed" class="search-list">
      <li class="search-list-item-meta error">
        <label>An error occured while an attempt to get invention collection.</label>
        <div></div>
      </li>
    </ul>
    <div v-else-if="isRequestPending || queue.length" style="justify-self: center; align-self: center">
      <hub-icon name="loading" spin size="xxlg"></hub-icon>
    </div>
    <ul v-else class="search-list">
      <li v-if="collection.length === 0 && searchText && searchText.length" class="search-list-item-meta">
        <label>Nothing found for '{{ searchText }}'.</label>
        <div></div>
      </li>
      <li v-if="collection.length !== 0" class="search-list-item-meta">
        <label>
          <span v-if="total > size">{{ size }} results of {{ total > 9999 ? 'more than 10000' : total.toString() }}</span>
          <span v-else>{{ collection.length }} results</span>
          <span v-if="searchText && searchText.length"> for '{{ searchText }}'</span>
        </label>
        <div></div>
      </li>
      <li v-for="item of collection" :key="item.id" class="search-list-item" @click="$emit('select', item)">
        <label>{{ item.title }}</label>
        <div>{{ item.references }}</div>
        <hub-button variant="icon"><hub-icon name="forwardburger"></hub-icon></hub-button>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Button from './../common/Button.vue';
import TextField from './../common/TextField.vue';
import Icon from './../common/Icon.vue';

export default {
  components: {
    'hub-button': Button,
    'hub-text-field': TextField,
    'hub-icon': Icon
  },
  emits: ['select', 'close'],
  data() {
    return {
      searchText: '',
      debounce: null,
      queue: []
    };
  },
  computed: {
    ...mapState({
      collection: s => s.inventions.collection,
      total: s => s.inventions.total,
      size: s => s.inventions.size,
      isRequestPending: s => s.inventions.isGetCollectionRequestPending,
      isRequestFailed: s => s.inventions.isGetCollectionRequestFailed
    })
  },
  watch: {
    searchText: {
      handler() {
        this.queue.push(this.searchText);
      },
      immediate: true
    }
  },
  created() {
    this.debounce = true;
    this.search();
  },
  unmounted() {
    this.debounce = false;
  },
  methods: {
    onEnter() {
      if (this.collection?.length !== 1) {
        return;
      }

      this.$emit('select', this.collection[0]);
    },
    async search() {
      const frame = this.queue.splice(0, this.queue.length);
      if (frame.length) {
        const text = encodeURIComponent(frame.pop());
        try {
          await this.$store.dispatch('inventions/getCollection', { text });
        } catch (e) {}
      }
      if (this.debounce) {
        setTimeout(this.search, 250);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  background: var(--theme-surface);
  color: var(--theme-on-surface);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: grid;
  padding: 2rem 15% 1rem 15%;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: max-content max-content minmax(0, 1fr);
  z-index: 100;

  .close-button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }

  .search-list {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
  }
  .search-list-item-meta {
    padding: 1rem 0.75rem;
    label {
      font-weight: 600;
      font-size: 0.9rem;
      color: var(--theme-on-surface-accent);
    }

    &.error {
      label {
        color: var(--theme-error);
      }
    }
  }
  .search-list-item {
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    grid-template-rows: max-content max-content;
    grid-gap: 0.25rem 0.5rem;
    cursor: pointer;
    label {
      font-weight: 600;
      font-size: 1.5rem;
      grid-column: 1/2;
      grid-row: 1/2;
      cursor: pointer;
    }

    div {
      font-size: 0.8rem;
      color: var(--theme-on-surface-accent);
      grid-column: 1/2;
      grid-row: 2/3;
    }

    button {
      grid-column: 2/3;
      grid-row: 1/3;
      align-self: center;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:hover {
      background-color: var(--theme-highlight);
    }
  }

  form {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, max-content);
    width: 100%;
    align-items: baseline;
  }
}
</style>

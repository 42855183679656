<template>
  <div>
    <hub-button color="primary" variant="text" @click="addTask"><slot /></hub-button>
    <hub-invention-lookup v-if="lookupVisible" @select="select" @close="close" />
  </div>
</template>

<script>
import Button from './../common/Button';
import Search from '../inventions/Search';

export default {
  components: {
    'hub-button': Button,
    'hub-invention-lookup': Search
  },
  emits: ['select'],
  data() {
    return {
      lookupVisible: false
    };
  },
  methods: {
    addTask() {
      this.lookupVisible = true;
    },
    select(invention) {
      this.$emit('select', invention);
      this.close();
    },
    close() {
      this.lookupVisible = false;
    }
  }
};
</script>

<template>
  <div :class="{ disabled, 'has-label': !!label }">
    <label v-if="!!label">{{ label }}</label>
    <ul>
      <li v-for="s of options" :key="s">
        <hub-checkbox :label="s" :value="value.includes(s)" :disabled="disabled" @input="change(s, $event)" />
      </li>
    </ul>
  </div>
</template>

<script>
import Checkbox from './Checkbox';

export default {
  components: {
    'hub-checkbox': Checkbox
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update'],
  methods: {
    change(name, value) {
      if (value) {
        // this.$emit('input', Array.from(new Set([...this.value, name])));
        // this.$emit('change', Array.from(new Set([...this.value, name])));
        this.$emit('update', Array.from(new Set([...this.value, name])));
      } else {
        // this.$emit(
        //   'input',
        //   this.value.filter(item => item !== name)
        // );
        this.$emit(
          'update',
          this.value.filter(item => item !== name)
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  display: grid;
  grid-template-rows: max-content;
  grid-gap: 5px;

  &.has-label {
    grid-template-rows: max-content max-content;
  }

  > * {
    min-width: 0;
    min-height: 0;
  }

  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }

  &.disabled {
    opacity: 0.5;
  }
}
</style>
